// Copied from https://github.com/Insight-Timer/enterprise/tree/develop/web-prod/src/styles/fonts

$fontName: "ProximaNova";

@mixin font-face(
  $font-name,
  $font-path,
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: auto;
    src: url("#{$font-path}.woff2") format("woff2"),
      url("#{$font-path}.woff") format("woff"),
      url("#{$font-path}.ttf") format("truetype"),
      url("#{$font-path}.svg") format("svg");
  }
}

/*

Defines from thin to thick characters. 400 is the same as normal, and 700 is the same as bold

Proxima Font-Sizes:
100, Thin
300, Light
400, Regular
500, Medium
600, Semi-bold
700, Bold
800, Extrabold
900, black
*/

@include font-face("#{$fontName}", "./ProximaNova-Thin", 100);
@include font-face("#{$fontName}", "./ProximaNova-Light", 300);
@include font-face("#{$fontName}", "./ProximaNova-Regular", 400);
@include font-face("#{$fontName}", "./ProximaNova-Medium", 500);
@include font-face("#{$fontName}", "./ProximaNova-Semibold", 600);
@include font-face("#{$fontName}", "./ProximaNova-Bold", 700);
@include font-face("#{$fontName}", "./ProximaNova-Extrabld", 800);
@include font-face("#{$fontName}", "./ProximaNova-Black", 900);
