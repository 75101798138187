$fontName: "Brandon";

@mixin font-face(
  $font-name,
  $font-path,
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: block;
    src: url("#{$font-path}.woff2") format("woff2"),
      url("#{$font-path}.woff") format("woff");
  }
}

@include font-face("#{$fontName}", "./BrandonTextWebBold/font", 700);
@include font-face("#{$fontName}", "./BrandonTextWebMedium/font", 500);
@include font-face("#{$fontName}", "./BrandonTextWebRegular/font", 400);
